import { REDIRECT_KEY } from '../enums';

const redirectPathnameBlockList = new Set(['/logout', '/login', '/auth0']);

export const redirectToLogin = () => {
  if (!redirectPathnameBlockList.has(location.pathname)) {
    localStorage.setItem(REDIRECT_KEY, location.pathname);
  }
  window.history.replaceState({}, document.title, '/login');
};

export const redirectToAzurePopUp = () => {
  if (!redirectPathnameBlockList.has(location.pathname)) {
    localStorage.setItem(REDIRECT_KEY, location.pathname);
  }
  window.history.replaceState({}, document.title, '/azure');
};

export const redirectToRequestAccess = () => {
  return window.history.replaceState({}, document.title, '/request-access');
};

export const redirectUser = () => {
  const storedRedirectPathname = localStorage.getItem(REDIRECT_KEY);

  const redirectTo =
    storedRedirectPathname?.startsWith('/') && !redirectPathnameBlockList.has(storedRedirectPathname)
      ? storedRedirectPathname
      : '/';

  window.history.replaceState({}, document.title, redirectTo);

  localStorage.removeItem(REDIRECT_KEY);
};
