import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import { createReactParcel } from '../utils/createReactParcel';
import { buildLoginUrl, jwt, isLoggedInAzureCheck } from '../utils/auth';
import Popup from '../components/access-dialog/Popup';
import { REDIRECT_KEY } from '../enums';

export const backgroundStyle = (backgroundUrl: string) => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 3;
  height: 100%;
  background:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${backgroundUrl}) no-repeat center center;
  background-size: cover;
`;

interface AzureLoginComponentProps {
  url: string;
  nonce: string;
  cdnUrl: string;
}

export const AzureLoginComponent: React.FC<AzureLoginComponentProps> = ({ url, nonce, cdnUrl }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [checkingLogin, setCheckingLogin] = useState(false);

  const isLoggedInAzure = async (email: string): Promise<boolean> => {
    try {
      const response = await fetch('https://w7inf4twxfnrjwrbdbusg3qzjq0hlscv.lambda-url.us-east-1.on.aws/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, checkLogin: true }),
      });

      if (response.status === 200) {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem(REDIRECT_KEY, '/');
        return true;
      }
      return false;
    } catch (_) {
      return false;
    }
  };

  const checkUserLogin = async () => {
    const payload = jwt();
    const email = payload?.email ?? '';

    if (!email) {
      return;
    }

    const userLoggedInAzure = await isLoggedInAzure(email);

    if (isLoggedInAzureCheck(payload) && userLoggedInAzure) {
      window.history.replaceState({}, document.title, '/');
      window.location.reload();
    } else {
      setShowPopup(true);
    }
  };

  // ✅ Run login check IMMEDIATELY if email exists in payload
  useEffect(() => {
    checkUserLogin();
  }, []);

  // ✅ Re-check login every second after clicking "Test Login"
  useEffect(() => {
    if (checkingLogin) {
      const interval = setInterval(checkUserLogin, 1000);
      return () => clearInterval(interval);
    }
  }, [checkingLogin]);

  return (
    <div id="azure-login" css={backgroundStyle(`${cdnUrl}/images/f1-pitstop.jpg`)}>
      {showPopup && (
        <Popup
          message="We are migrating from Auth0 to Azure. Please log in to test."
          onLogin={() => {
            window.open('https://test-azure.acc.dazn-dev.com/', '_blank');
            setCheckingLogin(true);
          }}
        />
      )}
    </div>
  );
};

export const azureLogin = createReactParcel({
  loadRootComponent: ({ CDN_URL, AUTH0_CLIENT_ID, AUTH0_CONNECTION, env }) => {
    const { url, nonce } = buildLoginUrl({
      auth0ClientId: AUTH0_CLIENT_ID,
      env,
      auth0Connection: AUTH0_CONNECTION,
    });

    return Promise.resolve(() => <AzureLoginComponent url={url} nonce={nonce} cdnUrl={CDN_URL} />);
  },
});
