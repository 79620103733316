import { RegisterApplicationConfig, pathToActiveWhen } from 'single-spa';

import { createProtectedModule, createACCModuleWrapper } from '../utils/module';
import { IS_IFRAME } from '../constants';
import { BaseEnvironmentConfig, ModuleEntry, ModuleEntryConfig, ModuleLink, CommonApplicationProps } from '../../types';

import logout from './logout';
import reauth from './reauth';
import fallback from './fallback';
import { nav } from './nav';
import { login } from './login';
import { azureLogin } from './azureLoginPopup';
import { requestAccess } from './requestAccess';
import { error } from './error';
import { notFound } from './notFound';
import { survey } from './survey';

export const getHomepageApplication = (): RegisterApplicationConfig<ModuleEntryConfig> => {
  return {
    name: 'fallback-dashboard',
    app: createProtectedModule(fallback),
    activeWhen: ({ pathname }) => pathname === '/',
    customProps: {},
  };
};

export const getUnprotectedCoreApplications = ({
  config,
}: {
  config: BaseEnvironmentConfig;
}): RegisterApplicationConfig<CommonApplicationProps>[] => [
  {
    name: 'login',
    app: login,
    activeWhen: ({ pathname }) => pathname === '/login',
    customProps: { ...config, enableClickTracking: true },
  },
  {
    name: 'azure',
    app: azureLogin,
    activeWhen: ({ pathname }) => pathname === '/azure',
    customProps: { ...config, enableClickTracking: true },
  },
  {
    name: 'request-access',
    app: requestAccess,
    activeWhen: ({ pathname }) => pathname === '/request-access',
    customProps: { ...config, enableClickTracking: true },
  },
  {
    name: 'error',
    app: error,
    activeWhen: ({ pathname }) => pathname === '/error',
    customProps: { ...config, enableClickTracking: true },
  },
];

const createImportFn = (js: string, config: BaseEnvironmentConfig) => async () => {
  const mod = await System.import(js);
  return typeof mod.default === 'function' ? mod.default(config) : mod.default;
};

const createACCModuleApplication = (mod: ModuleEntry, config: BaseEnvironmentConfig) => {
  if ('js' in mod) {
    return createProtectedModule(
      createACCModuleWrapper({
        name: mod.path,
        importFn: createImportFn(mod.js, config),
      })
    );
  }

  return createProtectedModule(
    createACCModuleWrapper({
      name: mod.path,
      iframeSrc: mod.iframe,
    })
  );
};

export const getAccModuleApplications = ({
  modules,
  config,
}: {
  modules: ModuleEntry[];
  config: BaseEnvironmentConfig;
}): RegisterApplicationConfig<ModuleEntryConfig>[] =>
  modules.map(mod => ({
    name: mod.path,
    app: createACCModuleApplication(mod, config),
    activeWhen: mod.exact ? ({ pathname }) => pathname === mod.path : mod.path,
    customProps: mod.config ? mod.config : {},
  }));

interface NavBarOptions {
  applicationsWithNav: RegisterApplicationConfig<ModuleEntryConfig>[];
  links: ModuleLink[];
}

export const getNavBar = ({
  applicationsWithNav,
  links,
}: NavBarOptions): RegisterApplicationConfig<{ links: ModuleLink[] }> => ({
  name: 'nav',
  app: nav,
  activeWhen: applicationsWithNav.flatMap(app => app.activeWhen),
  customProps: { links },
});
// Survey get over here
export const getSurveyWindow = (): RegisterApplicationConfig<ModuleEntryConfig> => ({
  name: 'survey',
  app: survey,
  activeWhen: ({ pathname }) => !['/login', '/logout', '/auth0', '/error'].includes(pathname),
});

interface ProtectedApplicationsOptions {
  allRoutedApplications: (
    | RegisterApplicationConfig<ModuleEntryConfig>
    | RegisterApplicationConfig<BaseEnvironmentConfig>
  )[];
  config: BaseEnvironmentConfig;
}

export const getProtectedCoreApplications = ({
  allRoutedApplications,
  config,
}: ProtectedApplicationsOptions): RegisterApplicationConfig<CommonApplicationProps>[] => {
  const allRoutedApplicationsActiveWhen = allRoutedApplications
    .flatMap(app => app.activeWhen)
    .map(activeWhen => (typeof activeWhen === 'string' ? pathToActiveWhen(activeWhen) : activeWhen));

  return [
    {
      name: 'not-found',
      app: notFound,
      activeWhen: location => allRoutedApplicationsActiveWhen.every(fn => !fn(location)),
      customProps: { ...config, enableClickTracking: true },
    },
    {
      name: 'reauth',
      app: reauth,
      activeWhen: () => !IS_IFRAME,
      customProps: config,
    },
    {
      name: 'logout',
      app: logout,
      activeWhen: ({ pathname }) => pathname === '/logout',
      customProps: config,
    },
  ];
};
