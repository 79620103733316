import navSections from '../src/config/nav-sections.json';

export enum Env {
  Dev = 'dev',
  Stage = 'stage',
  Production = 'production',
}

export interface BaseEnvironmentConfig {
  env: Env;
  CDN_URL: string;
  ACC_URL: string;
  SURVEY_URL: string;
  GRAPHQL_URL: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_CONNECTION: string;
  systemFlagsEnabledData?: string[];
}

export interface CommonApplicationProps extends BaseEnvironmentConfig, ModuleEntryConfig {}

export interface NavSection {
  order: number;
  name: string;
}

export type NavSections = {
  [name in keyof typeof navSections]: NavSection;
};

export interface RuntimeConfig extends BaseEnvironmentConfig {
  permissions: string[];
  navSections: NavSections;
  isExternalUser: boolean;
}

export interface ModuleConfigEnvironment {
  env: Env;
  CDN_URL: string;
  ACC_URL: string;
  /**
   * @deprecated Please use `hasPermission()` instead
   */
  permissions: string[];
  navSections: NavSections;
  hasPermission: (permission: string) => boolean;
  isExternalUser: boolean;
}

export interface ModuleEntryConfig {
  /**
   * If `true`, this will enable Google Analytics tracking of all clicks in your module.
   * Before turning this on, you should ensure that any elements containing Personally
   * Identifiable Information (PII) are marked as such by setting the CSS class `"PII"`
   * on them or a parent element.
   * @default false
   */
  enableClickTracking?: boolean;
  /**
   * A list of strings which should be omitted from the tracking of history changes by Google Analytics
   * (for example because the pathname itself contains PII). Strings must be in a form understood by
   * [path-to-regexp](https://github.com/pillarjs/path-to-regexp/) and will be prefixed with your
   * module's path, e.g. `"/user/:id/settings"`. Paths will be matched as prefixes, i.e. `"/user/:id"`
   * will block `/user-management/user/1` but also `/user-management/user/1/profile`.
   */
  pathnameTrackingBlocklist?: string[];
}

interface BaseModuleEntry {
  path: string;
  exact?: boolean;
  config?: ModuleEntryConfig;
  repository: {
    url: string;
    directory?: string;
  };
}

interface JSModuleEntry extends BaseModuleEntry {
  js: string;
}

interface FrameModuleEntry extends BaseModuleEntry {
  iframe: string;
}

export type ModuleEntry = FrameModuleEntry | JSModuleEntry;

export interface ModuleLink {
  section: NavSection;
  name: string;
  path: string;
  exact?: boolean;
}

export interface ModuleConfig {
  modules?: ModuleEntry[];
  links?: ModuleLink[];
}

export interface JWTPayload {
  'https://ldap.dazn.com/groups': string[];
  nonce: string;
  exp: number;
  iat: number;
  email: string;
}
